<template>
  <div class="download-file mt-3">
    <button
      @click="downloadSampleFile"
      class="button button_theme-default button_size-m"
    >
      <v-icon dark class="download-icon">mdi-cloud-download</v-icon>
      <span class="outer-space-button-text">Download Sample</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DownloadSampleFile',
  props: {},
  data: () => ({
    sampleData: [
      ['name', 'surname', 'company', 'department', 'manager', 'role', 'phone', 'corporateEmail'],
      ['abc', 'xyz', 'Innerworks', 'sales', 'Peter', 'employee', '44778376348', 'abc@innerworks.me'],
    ],
  }),
  methods: {
    downloadSampleFile() {
      const csvContent = `data:text/csv;charset=utf-8,${this.sampleData.map(e => e.join(',')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'sample-employee-file.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.download-file {
  display: flex;
  justify-content: center;
  button {
    display: flex;
  }
  .download-icon {
    margin-right: 10px;
  }
}
</style>
